import React from 'react';
import "./App.css";
// import Axios from 'axios'

var green = '#21dc40'
var gray = '#d9d9d9'
var ws = false


// select users page
function select_users_page(props) {
  return (
    <div className='users_page'>
      <h1>
        how are you?
      </h1>
      <div className='users_list'>
        {user_list(props)}
      </div>

    </div >
  )
}

function user_list(props) {
  return props.state.users.map((user) => {
    const { id, name, status } = user
    if (status[0] === "F") {
      return (
        <div key={id} style={{ padding: '10%' }}>
          <button onClick={() => update_status(props, id, name, status)} key={id} className="user_button" style={{ backgroundColor: gray }}>
            {name}
          </button>
        </div>
      )
    }
    else {
      return (
        <div key={id} style={{ padding: '10%' }}>
          <button onClick={() => update_status(props, id, name, status)} key={id} className="user_button" style={{ backgroundColor: green }}>
            {name}
          </button>
        </div>
      )
    }


  })
}

function update_status(props, id, name, status) {
  props.setState({
    page: 'start_blooping_page', me: { id: id, name: name, status: status }
  })
}

// start blooping page
function start_blooping_page(props) {
  if (ws === false) {
    ws = new WebSocket(`ws://192.168.0.28:5000/ws/${props.state.me.id}`);
  }

  ws.onmessage = (message) => {
    // message.data.replace(/^\D+/g, '') - 1
    console.log(props.state.users[message.data.replace(/^\D+/g, '') - 1]);
    var temp_users = props.state.users
    if (temp_users[message.data.replace(/^\D+/g, '') - 1].status[0] === "F") {
      temp_users[message.data.replace(/^\D+/g, '') - 1].status = "True"
    }
    else {
      temp_users[message.data.replace(/^\D+/g, '') - 1].status = "False"
    }
    props.setState({ users: temp_users })
  };

  if (props.state.me.status[0] === 'F') {
    return (
      <div>
        <div className='users_status_icons'>
          {users_status_icons(props)}
        </div>
        <div className='start_page'>
          <div className="main_button_div">
            <button className="main_button" style={{ backgroundColor: gray }} onClick={() => update_my_status(props)}>
              Start
            </button>
          </div>
        </div>
      </div>

    )
  }
  else {
    var date = new Date(Date.now())
    return (
      <div>
        <div className='users_status_icons'>
          {users_status_icons(props)}
        </div>
        <div className='start_page'>
          <div className="main_button_div">
            <button className="main_button" style={{ backgroundColor: green }} onClick={() => update_my_status(props)}>
              Stop
            </button>
          </div>
          <h1>
            Start time
          </h1>
          <p className='time_text'>
            {String(date.getHours()) + ":" + String((date.getMinutes() < 10 ? '0' : '') + date.getMinutes())}
            <br></br>
            {String(date.getDate()) + "/" + String(date.getMonth() + 1) + "/" + String(date.getFullYear())}
          </p>
        </div>
      </div>
    )
  }
}

function users_status_icons(props) {
  return props.state.users.filter(user => user.id !== props.state.me.id).map((user) => {
    const { id, name, status } = user
    if (status[0] === "F") {
      return (
        <button key={id} className="users_icons" style={{ backgroundColor: gray }}>
          {name[0]}
        </button>
      )
    }
    else {
      return (
        <button key={id} className="users_icons" style={{ backgroundColor: green }}>
          {name[0]}
        </button>
      )
    }
  })
}

function update_my_status(props) {
  var temp_me = props.state.me
  if (temp_me.status[0] === "T") {
    temp_me.status = "False"
  }
  else {
    temp_me.status = "True"
  }
  props.setState({
    me: temp_me
  })
  sendMessage(props)

}

function sendMessage(props) {
  var date = new Date(Date.now())
  var this_time = [String(date.getHours()), String((date.getMinutes() < 10 ? '0' : '') + date.getMinutes())]
  var this_date = [String(date.getDate()), String(date.getMonth() + 1), String(date.getFullYear())]
  var msg = props.state.me.id + "," + props.state.me.name + "," + this_time + "," + this_date + "," + props.state.me.status
  console.log(msg)
  ws.send(msg)
}




function Bloop(props) {
  console.log(props.state.page)
  if (props.state.page === 'select_users_page') {
    return (
      <div>
        {select_users_page(props)}
      </div>
    )
  }
  if (props.state.page === 'start_blooping_page') {
    return (
      <div>
        {start_blooping_page(props)}
      </div>
    )
  }
  if (props.state.page === 'add_user_page') {
    return (
      <div>
        {/* {add_user_page(props)} */}
      </div>
    )
  }
  else {
    return (
      <div>
        page not fond
      </div>
    )
  }
}
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 'select_users_page',
      me: { name: "Matan", status: "False" },
    };

  }
  componentDidMount() {
    fetch('https://192.168.0.28:5000/metadata/get_users/')
      .then(res => res.json())
      .then(json => {
        this.setState({ users: json, loading: false })
      })
      .catch(e => console.log(e))
  }

  render() {
    const { loading } = this.state;
    return loading ?
      <div>Bloop is Loading...</div>
      :
      // <div>not Loading...</div>
      <div className="App">
        {Bloop(this)}
      </div>
  }
}

export default App;
